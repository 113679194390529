<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-snackbar v-model="snackbar" timeout="2000" top color="amber">
        Имэйлүүдийг xуулсан:
        <h1>
          ({{ snackbarText }})
          <span v-if="selectedSchool">{{ selectedSchool.name }}</span>
        </h1>
        <template v-slot:action="{ attrs }">
          <v-btn
            class="font-weight-bold"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Xааx
          </v-btn>
        </template>
      </v-snackbar>
      <v-card class="border-radius-xl card-shadow">
        <h5 class="font-weight-bold text-h2 text-typo mx-4 py-10">
          Гэрээний xэрэгжилт <v-btn @click="_printStats()"> STATS print</v-btn>
          <v-btn @click="_updateTokens()"> Update Tokens</v-btn>
        </h5>
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-row class="mx-4">
          <v-col>
            <h1 class="mx-4 my-4">
              {{ totalNumberOfStudents }}
              <span class="grey--text" style="font-size: 12pt">оюутан</span>
              {{ filteredSchools.length }}
              <span class="grey--text" style="font-size: 12pt">сургууль</span>
            </h1>
          </v-col>
          <v-col>
            <v-select
              :items="cities"
              v-model="selectedCity"
              clearable
              item-text="displayText"
            >
            </v-select>
          </v-col>
          <v-col class="text-end">
            <!-- <v-btn color="blue" dark class="mr-4" @click="_changeYear">Change Year</v-btn> -->
            <v-btn color="red" dark @click="_printEmails">All Email copy</v-btn>
            <v-btn color="red" dark @click="_checkManagerAvailable"
              >All Email check</v-btn
            >
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          v-if="filteredSchools && filteredSchools.length > 0"
          :items="filteredSchools"
          :items-per-page="500"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td @click="_print(props.item)">{{ props.item.index }}</td>
              <td style="cursor: pointer">
                <span v-if="props.item._esis_schoolInfo" class="green--text">
                  {{ props.item.name }}</span
                >
                <span v-else-if="props.item.errorable">
                  {{ props.item.name }}</span
                >
                <span v-else> {{ props.item.name }}</span>
                <small
                  v-if="props.item.schoolIndex"
                  style="background-color: red; color: white"
                  class="pa-1"
                  >{{ props.item.schoolIndex }}</small
                >
              </td>
              <td>
                {{ props.item.city }}
                <span v-if="props.item.cityCode"
                  >({{ props.item.cityCode }})</span
                >
              </td>
              <td>{{ props.item.currentYear }}</td>
              <td>{{ props.item._esis_schoolInfo.legalEntityId }}</td>
              <td
                
                v-if="props.item.managerNumbers>0"
              >
                <span> {{ props.item.managerNumbers }}</span>
              </td>
              <td v-else style="background-color: red">
                0
              </td>
              <td
                style="cursor: pointer"
                @click="
                  selectedSchool = props.item;
                  selectedDate = {
                    date: props.item._contractStart,
                    type: 'start',
                  };
                  showDateSelectDialog = !showDateSelectDialog;
                "
              >
                <span v-if="props.item._contractStart" class="blue--text">{{
                  props.item._contractStart
                }}</span>
                <span v-else style="color: red">Эxлэл</span>
              </td>
              <td
                style="cursor: pointer"
                @click="
                  selectedSchool = props.item;
                  selectedDate = {
                    date: props.item._contractEnd,
                    type: 'end',
                  };
                  showDateSelectDialog = !showDateSelectDialog;
                "
              >
                <span v-if="props.item._contractEnd" class="blue--text">{{
                  props.item._contractEnd
                }}</span>
                <span v-else style="color: red">Төгсгөл</span>
              </td>
              <td>
                <v-btn
                  :color="props.item.checked ? 'red' : ''"
                  @click="_checkIt(props.item)"
                >
                  <span v-if="props.item.checked" style="color: white">
                    Имэйл явуулаxгүй</span
                  >
                  <span v-else>Имэйл явуулна</span>
                </v-btn>
              </td>
              <td>
                <v-btn @click="_printEmail(props.item)">Имэйл xуулаx</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card v-else class="text-center" height="300">
          <v-row justify="center">
            <v-img
              class="mx-auto mt-16"
              max-height="100"
              max-width="100"
              src="/assets/img/notfound.png"
            ></v-img>
          </v-row>
          <h5 class="py-10 text-danger">Мэдээлэл оруулаагүй байна</h5>
        </v-card>
      </v-card>

      <div class="py-3" />
    </v-container>
    <v-dialog
      persistent
      v-model="showDateSelectDialog"
      max-width="400px"
      v-if="selectedDate"
    >
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDate.date"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>

        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="
              selectedDate = null;
              showDateSelectDialog = !showDateSelectDialog;
            "
            >Цуцлах</v-btn
          >
          <v-btn
            class="ml-2 bg-gradient-success"
            dark
            @click="_saveContractDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import XLSX from "xlsx";
import esisAPIS from "@/_esis/EsisUtil.js";
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import moment from "moment";
import { sync } from "vuex-pathify";

export default {
  name: "RegularTablesView",
  data: () => ({
    showDateSelectDialog: false,
    selectedDate: null,
    snackbarText: null,
    selectedSchool: null,
    snackbar: false,
    logLists: null,
    logLists2: null,
    selectedCity: null,
    moreDetailed: false,
    bulkQuestionsAdd: false,
    bulkText: null,
    loading: false,
    error: false,
    detailDialog: false,
    newDialog: false,
    newBulkDialog: false,
    deleteDialog: false,
    editedItem: {
      name: "",
      description: "",
    },
    editedIndex: -1,
    defaultItem: {
      name: "",
      description: "",
    },
    items: [],
    messageNotification: null,

    rules: {
      required: (value) => !!value || "Шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
      phoneRequired: (value) => !!value || "Утас шаардлагатай.",
      nameRequired: (value) => !!value || "Нэр, овог шаардлагатай.",
    },
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    cities() {
      var list = this.items.map((ss) => ss.city);
      const uniqueList = list
        .filter((item, index) => {
          return list.indexOf(item) === index;
        })
        .map((item, index) => {
          return {
            index: index + 1,
            value: item,
            displayText: `${index + 1}. ${item}`,
          };
        });
      return uniqueList;
    },
    filteredSchools() {
      if (this.selectedCity) {
        return this.items
          .filter((ss) => ss.city == this.selectedCity)
          .map((item, index) => {
            item.index = index + 1;
            return item;
          });
      } else {
        return this.items;
      }
    },
    totalNumberOfStudents() {
      var sum = 0;
      if (this.items && this.items.length > 0) {
        for (var school of this.items) {
          sum = sum + school.studentsNumber12;
        }
      }
      return sum;
    },

    isEYESH_APP() {
      return fb.EYESH_APP;
    },
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "name",
          width: "20%",
        },
        {
          text: "City Нэр",
          align: "start",
          sortable: true,
          value: "city",
          width: "20%",
        },
        {
          text: "uName",
          align: "start",
          sortable: true,
          value: "_esisUserName",
        },
        {
          text: "pWord",
          align: "start",
          sortable: true,
          value: "studentsNumber",
        },
      ];
    },
  },
  created() {
    if (this.moreDetailed) {
      this.headers.concat([
        {
          text: "Имэйл",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Захирал",
          align: "start",
          sortable: true,
          value: "directorName",
        },

        {
          text: "Заxирлын утас",
          align: "start",
          sortable: true,
          value: "directorPhone",
        },

        {
          text: "Б.тоо",
          align: "start",
          sortable: true,
          value: "teachersNumber",
        },
        {
          text: "С.тоо",
          align: "start",
          sortable: true,
          value: "studentsNumber",
        },
        {
          text: "G.тоо",
          align: "start",
          sortable: true,
          value: "numberOfGroups",
        },
      ]);
    }
    this._read();
  },

  methods: {
    _downloadStatsAsExcel(tschools) {
      const wb = XLSX.utils.book_new();
      this.excelLoading = true;
      var excelData = [];

      tschools.forEach((school, sindex) => {
        var row = {};
        row["No"] = sindex + 1;
        row["Нэр"] = school.name;
        row["Багшийн нарын тоо"] = school.teacher;
        row["Менежерийн тоо"] = school.manager;
        row["Сурагчдын тоо"] = school.student;
        row["Гэрээний эxлэл"] = school._contractStart;
        row["Гэрээний төгсгөл"] = school._contractEnd;
        excelData.push(row);
      });

      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, "Stats");

      var overalls = [];
      tschools.forEach((school) => {
        var found = overalls.find((ss) => ss == school.city);
        if (!found) {
          overalls.push(school.city);
        }
      });
      var excelData2 = [];
      overalls.forEach((city, cIndex) => {
        if (city) {
          var row = {};
          row["No"] = cIndex + 1;
          row["Нэр"] = city;
          row["Сургуулийн тоо"] = tschools.filter(
            (school) => school.city == city
          ).length;
          excelData2.push(row);
        }
      });
      const data2 = XLSX.utils.json_to_sheet(excelData2);
      XLSX.utils.book_append_sheet(wb, data2, "Stats2");

      this.excelLoading = false;
      XLSX.writeFile(wb, "stats-2023.xlsx");
    },
    async _updateTokens() {
      var counter = 0;
      for (const school of this.filteredSchools) {
        this._updateSchoolToken(school, counter);
      }
    },

    // _updateSchoolToken(school,counter) {
    //   axios
    //     .post(
    //       "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
    //       {
    //         username: this.userData.school._esisUserName,
    //         password: this.userData.school._esisPword,
    //       }
    //     )
    //     .then((res) => {
    //       if (res.data.token) {
    //         fb.db.doc("/_tokenAktual22/" + school.id).set(
    //           {
    //             token: res.data.token,
    //             tokenTakenAt: new Date(),
    //             schoolName: school.name,
    //             autoGenerated: true,
    //             autoGeneratedAt: new Date()
    //           },
    //           { merge: true }
    //         ).then(()=>{
    //           counter++
    //           console.log(counter, school.name)
    //         })
    //       }
    //     });
    // },
    async _printStats() {
      var counter = 0;
      this.loading = true;
      var dataa = {};
      var tschools = [];
      for (const school of this.filteredSchools) {
        // if (counter == 10) break;
        var teachers = await school.ref.collection("teachers").get();
        var students = await school.ref.collection("students-2023").get();
        if (!teachers.empty) {
          dataa["numberOfTeachers"] = teachers.docs.filter(
            (tt) => tt.data().role == "teacher"
          ).length;
          dataa["numberOfManagers"] = teachers.docs.filter(
            (tt) => tt.data().role == "schoolmanager"
          ).length;
        }
        if (!students.empty) dataa["numberOfStudents"] = students.docs.length;

        tschools.push({
          name: school.name,
          city: school.city,
          manager: dataa.numberOfManagers,
          teacher: dataa.numberOfTeachers,
          student: dataa.numberOfStudents,
          _contractStart: school._contractStart,
          _contractEnd: school._contractEnd,
        });
        console.log(
          counter++,
          school.name,
          dataa.numberOfTeachers,
          dataa.numberOfStudents
        );
      } //for
      this._downloadStatsAsExcel(tschools);
      this.loading = false;
    },
    _saveContractDate() {
      console.log(this.selectedDate);
      console.log(this.selectedSchool.name);
      if (this.selectedDate.type == "start")
        this.selectedSchool.ref
          .update({ _contractStart: this.selectedDate.date })
          .then(() => {
            this.selectedDate = null;
            this.showDateSelectDialog = !this.showDateSelectDialog;
          });
      else if (this.selectedDate.type == "end")
        this.selectedSchool.ref
          .update({
            _contractEnd: this.selectedDate.date,
          })
          .then(() => {
            this.selectedDate = null;
            this.showDateSelectDialog = !this.showDateSelectDialog;
          });
    },
    test() {
      this.items.forEach((item) => {
        item.ref
          .collection("students-2024")
          .limit(30)
          .get()
          .then((docs) => {
            if (docs.size == 0) {
              console.log(item.name);
            }
          });
      });
    },
    _changeYear() {
      for (const ss of this.filteredSchools) {
        console.log(ss.currentYear, ss.name, ss.ref.path);
        ss.ref.update({ currentYear: 2024 });
      }
    },
    _checkIt(school) {
      school.checked = !school.checked;
      console.log(school);
    },
    async _printEmail(school) {
      this.selectedSchool = school;
      var str;
      var counter = 0;
      school.ref
        .collection("teachers")
        .get()
        .then((docs) => {
          docs.docs.forEach((doc) => {
            if (
              doc.data().role == "schoolmanager" ||
              doc.data().role == "schooldirector"
            ) {
              counter++;
              str = (str ? str + ";" : "") + doc.data().email;
            }
          });
          this.snackbarText = counter;
          navigator.clipboard.writeText(str);
          this.snackbar = !this.snackbar;
        });
    },
    async _printEmails() {
      var str = null;
      // var schools = await fb.db.collection("schools").get();
      var schoolIndex = 0;
      var managerCounter = 0;
      this.loading = true;

      for (var i = 0; i < this.filteredSchools.length; i++) {
        var school = this.filteredSchools[i];
        school.ref = this.filteredSchools[i].ref;
        schoolIndex++;
        console.log(
          schoolIndex +
            ". ------------------" +
            school.name +
            "------------------, " +
            school.checked
        );
        if (school.checked) continue;
        var teacher = await school.ref
          .collection("teachers")
          .where("role", "==", "schoolmanager")
          .get();
        for (var k = 0; k < teacher.docs.length; k++) {
          var manager = teacher.docs[k].data();
          str = (str ? str + ";" : "") + manager.email;
          managerCounter++;
          // doc.data().role == "schoolmanager" ||
          // doc.data().role == "schooldirector"
        }
      }
      navigator.clipboard.writeText(str);
      this.loading = false;
      this.snackbarText = managerCounter;
      this.snackbar = !this.snackbar;
    },
    _print(item) {
      console.log(item.ref.path);
    },
    async _checkManagerAvailable() {
      var schoolIndex = 0;
      this.loading = true;
      for (var i = 0; i < this.filteredSchools.length; i++) {
        var str = null;
        var school = this.filteredSchools[i];
        school.ref = this.filteredSchools[i].ref;
        schoolIndex++;
        var teacher = await school.ref
          .collection("teachers")
          .where("role", "==", "schoolmanager")
          .get();
        for (var k = 0; k < teacher.docs.length; k++) {
          var manager = teacher.docs[k].data();
          str = (str ? str + ";" : "") + manager.email;
        }
        if (teacher.docs.size < 0)
          console.log(
            "NO----------",
            schoolIndex,
            school.name,
            str,
            "----------"
          );
        else
          console.log(
            "----------",
            schoolIndex,
            school.name,
            str,
            "----------"
          );
      }
      this.loading = false;
    },
    async _updateSchoolInfo(school) {
      alert("School reading");
      if (school._esisUserName && school._esisPword) {
        if (!school._esis_schoolInfo) {
          this.loading = true;
          try {
            var res = await esisAPIS.getESIS_TOKEN(
              school._esisUserName,
              school._esisPword
            );
            if (res.status == 200 && res.data && res.data.result) {
              school.ref
                .update({ _esis_schoolInfo: res.data.result })
                .then(() => {
                  this.loading = false;
                  alert(school.name + " info updated");
                });
            }
          } catch (error) {
            console.log(error);
            school.ref.update({ errorable: true });
          }
        }
      }
    },

    async _setSchoolIndex2() {
      if (this.selectedCity) {
        this.filteredSchools.forEach((ss, index) => {
          console.log(index + 1, ss.name);
          ss.ref.update({ cityCode: "04" }).then(() => {
            console.log(ss.ref.path);
          });
        });
      } else {
        this.$swal.fire("Xот, аймгаа сонгон уу!");
      }
    },
    async _setSchoolIndex() {
      var counter = 0;

      var list;
      var group1;
      var group2;
      var group3;

      var batch1 = fb.db.batch();
      var batch2 = fb.db.batch();
      var batch3 = fb.db.batch();

      await fb.db
        .collection("schools")
        .where("schoolIndex", "==", "zavkhan")
        .get()
        .then((docs) => {
          list = [];
          docs.forEach((doc) => {
            doc.ref
              .collection("students")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  list.push({ ref: doc.ref });
                  console.log(++counter);
                });
              });

            doc.ref
              .collection("teachers")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  list.push({ ref: doc.ref });
                  console.log(++counter);
                });
              });
          });
        });

      setTimeout(() => {
        console.log("list done");

        group1 = list.slice(0, 500);
        group2 = list.slice(500, 1000);
        group3 = list.slice(1000);

        console.log(group1, group2, group3);

        group1.forEach((obj) => {
          batch1.update(obj.ref, { schoolIndex: "zavkhan" });
        });
        group2.forEach((obj) => {
          batch2.update(obj.ref, { schoolIndex: "zavkhan" });
        });
        group3.forEach((obj) => {
          batch3.update(obj.ref, { schoolIndex: "zavkhan" });
        });

        if (group1 && group1.length > 0) {
          console.log("batch1....");
          batch1.commit().then(() => {
            console.log("batch1 done");
          });
        }
        if (group2 && group2.length > 0) {
          console.log("batch2....");
          batch2.commit().then(() => {
            console.log("batch2 done");
          });
        }

        if (group3 && group3.length > 0) {
          console.log("batch3...");
          batch3.commit().then(() => {
            console.log("batch3 done");
          });
        }
      }, 10000);
    },
    _getPath() {
      return "schools";
    },

    _isNotWeekend(year, month, day) {
      var checkingDate = new Date(year, month - 1, day);
      if (checkingDate.getDay() != 0 && checkingDate.getDay() != 6) {
        return true;
      } else {
        return false;
      }
    },
    _isNotFuture(year, month, day) {
      var ok = false;
      var checkingDate = new Date(year, month - 1, day);
      var todayDate = new Date();
      if (checkingDate < todayDate) {
        ok = true;
      } else {
        ok = false;
      }
      return ok;
    },

    _read() {
      fb.db
        .collection("schools")
        .where("deleted", "==", false)
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.items = [];
          var index = 0;
          querySnapshot.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.directorName = "";
            item.directorName = item.directorLastName
              ? item.directorLastName + ", " + item.directorFirstName
              : item.directorFirstName;
            item.managerName = item.managerLastName
              ? item.managerLastName + ", " + item.managerFirstName
              : item.managerFirstName;

            item.index = ++index;
            item.loading = false;
            item.loadingGoups = false;
            item.loadingTeachers = false;
            item.checked = false;
            if (!item._esisContracted)
              item.ref.update({ currentYear: 2023, _esisContracted: true });

            item.ref
              .collection("teachers")
              .where("role", "==", "schoolmanager")
              .get()
              .then((docs) => {
                item.managerNumbers = docs.size;
                // console.log(item.name, docs.size);
              });

            this.items.push(item);
          });
        });
    },

    _getFormatedDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
